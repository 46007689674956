import { lazy } from "react";
import RequireAuth from "./RequireAuth";

const MyVideoAdmin = lazy(() => import("./pages/Admin/myVideoAdmin"));
const EditVideo = lazy(() => import("./pages/Admin/editVideo"));
const AnalysisPage = lazy(() => import("./pages/Admin/analysis"));
const Payment = lazy(() => import("./pages/Payment/extendPayment"));
const MyAccount = lazy(() => import("./pages/User/myAccount"));
const MyVideo = lazy(() => import("./pages/Admin/myVideoAdmin"));
// const ChangePw = lazy(() => import("./pages/User/changePsw"));

export const Path = [
  {
    path: "/",
    component: lazy(() => import("./pages/Home/main")),
  },
  {
    path: "/myVideoAdmin",
    component: () => (
      <RequireAuth
        Component={MyVideoAdmin}
        permissionKey="permission"
        admin={true}
      />
    ),
  },
  {
    path: "/edit",
    component: () => (
      <RequireAuth
        Component={EditVideo}
        permissionKey="permission"
        admin={true}
      />
    ),
  },
  {
    path: "/analysis",
    component: () => (
      <RequireAuth
        Component={AnalysisPage}
        permissionKey="permission"
        admin={true}
      />
    ),
  },
  {
    path: "/myAccount",
    component: () => (
      <RequireAuth
        Component={MyAccount}
        permissionKey="permission"
        admin={false}
      />
    ),
  },
  {
    path: "/myVideo",
    component: () => (
      <RequireAuth
        Component={MyVideo}
        permissionKey="permission"
        admin={false}
      />
    ),
  },
  {
    path: "/payment",
    component: () => (
      <RequireAuth
        Component={Payment}
        permissionKey="permission"
        admin={false}
      />
    ),
  },
  {
    path: "/change-password",
    component: lazy(() => import("./pages/User/changePsw")),
    // component: () => (
    //   <RequireAuth
    //     Component={ChangePw}
    //     permissionKey="permission"
    //     admin={false}
    //   />
    // ),
  },
  {
    path: "/upload",
    component: lazy(() => import("./pages/Admin/uploadVideo")),
  },

  {
    path: "/video",
    component: lazy(() => import("./pages/Video/videoPage")),
  },
  {
    path: "/scenes",
    component: lazy(() => import("./pages/Video/allvideo")),
  },
  {
    //path: "/model-list",
    path: "/models",
    //component: lazy(() => import("./pages/Model/ourModel")),
    component: lazy(() => import("./pages/Model/modeldesc")),
  },
  {
    //path: "/series-list",
    path: "/series",
    component: lazy(() => import("./pages/Series/ourSeries")),
  },
  {
    path: "/register",
    component: lazy(() => import("./pages/Login/register")),
  },

  {
    path: "/reg",
    component: lazy(() => import("./pages/Login/register")),
  },
  {
    path: "/success",
    component: lazy(() => import("./pages/Payment/paymentSuccess")),
  },
  {
    path: "/failed",
    component: lazy(() => import("./pages/Payment/paymentFailed")),
  },

  {
    path: "/coinpurchase",
    component: lazy(() => import("./pages/Shop/buyCoin")),
  },
  {
    path: "/stars",
    component: lazy(() => import("./pages/Model/ourModel")),
  },
  {
    path: "/msg",
    component: lazy(() => import("./pages/Terms/text")),
  },
  {
    path: "/contact",
    component: lazy(() => import("./pages/Terms/contactUs")),
  },
  {
    path: "/dolly",
    component: lazy(() => import("./pages/Model/dolly")),
  },
  // {
  //   path: "/DwI9spy3bYC533sjXsVo",
  //   component: lazy(() => import("./pages/Admin/admin")),
  // },
  {
    path: "/login",
    component: lazy(() => import("./pages/Login/loginPage")),
  },
  {
    path: "*",
    component: lazy(() => import("./pages/Error/errormsg")),
  }
];
